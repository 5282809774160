body {
    margin: 0;
    font-family: "Lato";
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Lato";
    font-weight: normal;
}
@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Regular.eot");
    src: url("./assets/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/Lato-Regular.woff2") format("woff2"), url("./assets/fonts/Lato-Regular.woff") format("woff"),
        url("./assets/fonts/Lato-Regular.ttf") format("truetype"),
        url("./assets/fonts/Lato-Regular.svg#Lato-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Black.eot");
    src: url("./assets/fonts/Lato-Black.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/Lato-Black.woff2") format("woff2"), url("./assets/fonts/Lato-Black.woff") format("woff"),
        url("./assets/fonts/Lato-Black.ttf") format("truetype"),
        url("./assets/fonts/Lato-Black.svg#Lato-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Bold.eot");
    src: url("./assets/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/Lato-Bold.woff2") format("woff2"), url("./assets/fonts/Lato-Bold.woff") format("woff"),
        url("./assets/fonts/Lato-Bold.ttf") format("truetype"),
        url("./assets/fonts/Lato-Bold.svg#Lato-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Light.eot");
    src: url("./assets/fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/Lato-Light.woff2") format("woff2"), url("./assets/fonts/Lato-Light.woff") format("woff"),
        url("./assets/fonts/Lato-Light.ttf") format("truetype"),
        url("./assets/fonts/Lato-Light.svg#Lato-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato Hairline";
    src: url("./assets/fonts/Lato-Hairline.eot");
    src: url("./assets/fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"),
        url("./assets/fonts/Lato-Hairline.woff2") format("woff2"),
        url("./assets/fonts/Lato-Hairline.woff") format("woff"),
        url("./assets/fonts/Lato-Hairline.ttf") format("truetype"),
        url("./assets/fonts/Lato-Hairline.svg#Lato-Hairline") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
