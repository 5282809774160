.user-unauthorized {
    position: absolute;
    top: 50%;
    left: 50%;
    align-items: center;
    text-align: center;
    margin-left: -220px;
    margin-top: -60px;
}
.closeRedIcon {
    width: 40px;
}
.unauthorized-title {
    font-weight: 600;
    font-size: 40px;
}
.user-unauthorized span {
    color: #005397 !important;
    font-weight: 600;
    cursor: pointer;
}