.myPlann-modal{
    min-width: 1000px;
    box-sizing: border-box;
}
.modal-box{
    font-size: 14px;
}
.modal-box-2{
    font-size: 14px;
}
.modal-font-20{
    font-size: 20px;
}
.modal-border{
    border: 1px solid #DADCE0;
    border-radius: 8px;    
    color: #868686 !important;
    overflow: hidden;
}
.modal-drak-color{
    color: #4C4F53;
    font-weight: 500;
    font-size: 15px;
}
.tableData {
  font-size: 15;
  font-family: "Lato";
  color: #868686;
}
.tableHeader {
  font-family: "LatoBold";
  font-size: 13;
  text-transform: "uppercase";
  color: "#0D0F12";
}   
.discount-color{
    color:#073d83;
}
.flex-apply{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.modal-font-11{
    font-size: 11px !important;
    color: #868686 !important; 
}
.padding-20{
    padding: 20px;
}
.padding-10{
    padding: 10px;
}
.modal-font-16{
    font-size: 16px;
}
.cancel-modal{
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
}
.cancel-modla-heading{
    font-size: 20px;
    color: #0D0F12;
    margin: 20px 0px;
}
.cancel-modla-paragraph{
    font-size: 14px;
    color: #868686;
}
.margin-b-27{
    margin-bottom: 27px;
}
.paddingTop-10{
    padding-top: 20px;
}
.link-color{
    color:#073d83;
}

.close-icon{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color:#6a6d70;
    padding-right: 20px;
}

.table-stamp{
    max-height: 400px;
    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 5px;
}
.stampBox-12 {
    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 5;
    width: 100%;
    overflow: hidden;
    max-height: 160px;
}
.header-div{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.link-div{
    color:#073d83;
    font-size: 16px;
    font-family: "LatoBold";
    cursor: pointer;
}
.font-we-700{
    font-weight: 700;
}
.cursor-poiter{
    cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .myPlann-modal{
        min-width: 600px;
        box-sizing: border-box;
    }
    .max-padding{ 
    margin-top: 10px !important;
    }
}

@media only screen and (max-width: 500px) {
    .myPlann-modal{
        min-width: 350px;
        box-sizing: border-box;
    }
}


@media only screen and (max-width: 320px) {
    .myPlann-modal{
        min-width: 300px;
        box-sizing: border-box;
    }
}


.my-modal-width{
    width: 450px;
}