.transiton-02s {
  transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}

.z-index1 {
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.validateButtonSection {
  margin-top: 15%;
  position: relative;
  display: flex;
  width: 90%;
  justify-content: space-between;

  @media (max-width: 1399px) {
    width: 470px;
    margin-top: 9%;
  }

  @media (max-width: 1199px) {
    width: 382px;
    justify-content: space-between;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    width: 60%;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    width: 90%;
    justify-content: space-between;
  }
}

.validateButton {
  display: inline-block;
  width: 150px;

  @media (max-width: 1199px) {
    width: 30%;
  }
}

.videoPlay {
  background-image: url("https://account-files-bucket.s3.ap-south-1.amazonaws.com/bstamp/assets/get_started.png");
  width: 131px;
  height: 131px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: relative;
  transform: rotate(10deg);

  @media (max-width: 1199px) {
    width: 111px;
    height: 111px;
  }

  @media (max-width: 767px) {
    width: 71px;
    height: 71px;
  }

  .videoCircle {
    height: 55px;
    width: 55px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    background-color: #007de4;
    top: 29%;
    left: 29%;
    transform: rotate(-10deg);

    @media (max-width: 1199px) {
      top: 25%;
      left: 27%;
    }

    @media (max-width: 767px) {
      top: 19%;
      left: 20%;
      height: 45px;
      width: 45px;
    }

    .fa-play {
      position: absolute;
      right: 19px;
      bottom: 20px;

      @media (max-width: 767px) {
        font-size: 13px;
        right: 16px;
        bottom: 16px;
      }
    }
  }
}

.arrow {
  @media (max-width: 767px) {
    width: 20%;
  }
}

.videomodle {
  width: 889px;
  height: 500px;

  @media (max-width: 767px) {
    margin-left: -2%;
  }

  .modal-header {
    position: absolute;
    right: -35px;
    top: -35px;
    z-index: 99;
    font-size: 14px !important;
    border-bottom: none;

    h5 {
      div {
        cursor: pointer;
        display: flex;
        border: 1px solid #073d83;
        border-radius: 50%;
        overflow: hidden;
        width: 40px;
        height: 40px;
        background-color: #073d83;
        color: #fff !important;
      }
    }
  }

  .modal-content {
    width: auto;
  }
}

.bstamp-landing {
  &.search-open {
    overflow: hidden;
    max-height: 100vh;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #868686;

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  button {
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.form-control {
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    color: #868686;
  }

  &::-moz-placeholder {
    color: #868686;
  }

  &:-ms-input-placeholder {
    color: #868686;
  }

  &:-moz-placeholder {
    color: #868686;
  }
}

.bstamp-button {
  font-size: 18px;
  line-height: 22px;
  padding: 14px 40px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid;
  @extend .transiton-02s;

  @media (max-width: 1399px) {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 30px;
  }

  @media (max-width: 1199px) {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 20px;
  }

  @media (max-width: 767px) {
    padding: 8px 15px;
  }
}

.bstamp-blue-button {
  @extend .bstamp-button;
  background-color: #007de4;
  border-color: #007de4;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

.bstamp-white-button {
  @extend .bstamp-button;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #073d83;

  &:hover {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #073d83 !important;
  }
}

.bstamp-blueborder-button {
  @extend .bstamp-button;
  background-color: transparent;
  border-color: #007de4;
  color: #007de4;

  &:hover {
    background-color: #007de4;
    border-color: #007de4;
    color: #ffffff;
  }
}

.submit-button {
  &:hover {
    background-color: #007de4 !important;
    border-color: #007de4 !important;
    color: #ffffff !important;
  }
}

.learn-more {
  &:hover {
    background-color: #007de4 !important;
    border-color: #007de4 !important;
    color: #ffffff !important;
  }
}

.bstamp-whiteborder-button {
  @extend .bstamp-button;
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;

  &:hover {
    background-color: #007de4 !important;
    border-color: #007de4 !important;
    color: #ffffff !important;
  }
}

.banner-hash-button {
  &:hover {
    background-color: #007de4 !important;
    border-color: #007de4 !important;
    color: #ffffff !important;
  }
}

.bstamp-section {
  padding: 100px 0;

  @media (max-width: 1399px) {
    padding: 80px 0;
  }

  @media (max-width: 1199px) {
    padding: 50px 0;
  }

  @media (max-width: 991px) {
    padding: 40px 0;
  }

  @media (max-width: 767px) {
    padding: 30px 0;
  }
}

.fixwidth-section {
  max-width: calc(100% - 120px);
  margin: auto;
  border-radius: 20px;

  @media (max-width: 1399px) {
    max-width: calc(100% - 80px);
  }

  @media (max-width: 1199px) {
    max-width: calc(100% - 60px);
    border-radius: 15px;
  }

  @media (max-width: 991px) {
    max-width: calc(100% - 40px);
  }

  @media (max-width: 767px) {
    max-width: calc(100% - 30px);
    border-radius: 10px;
  }
}

.btsamp-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  padding: 20px;
  @extend .transiton-02s;

  &.header-sticky {
    position: fixed;
    background-color: #073d83;
  }

  @media (max-width: 767px) {
    padding: 10px 15px;
  }

  .hash-button {
    svg {
      fill: #ffffff;
    }

    @media (max-width: 767px) {
      padding: 5px 10px;
      background-color: transparent;
      border: none;
    }
  }

  .logo {
    @media (max-width: 767px) {
      max-width: 120px;
    }
  }

  .container {
    @media (max-width: 1199px) {
      padding: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  .login-button {
    @media (max-width: 767px) {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}

.box-link {
  color: #007de4;
  font-weight: bold;
  cursor: pointer;
}

.view-more-box {
  color: #007de4;
  font-weight: bold;
  cursor: pointer;
}

.banner-section {
  background-color: #073d83;
  color: #ffffff;
  min-height: 100vh;
  overflow: hidden;
  padding: 90px 0;

  /* @media (max-width: 1024px) {
    min-height: 50vh;
  } */
  @media (max-width: 767px) {
    padding: 90px 0 30px 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    height: 900px;
    width: 900px;
    background-repeat: no-repeat;
    background-size: contain;
    max-height: 84%;
    max-width: 47%;

    @media (max-width: 991px) {
      max-height: 84%;
      max-width: 60%;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      max-width: 80%;
    }
  }

  &:before {
    background-image: url("https://account-files-bucket.s3.ap-south-1.amazonaws.com/bstamp/assets/bstamp-images/blue-roung-left.svg");
    left: -11%;
    top: -5%;
    background-position: top left;
  }

  &:after {
    background-image: url("https://account-files-bucket.s3.ap-south-1.amazonaws.com/bstamp/assets/bstamp-images/blue-roung-right.svg");
    right: -10%;
    bottom: -10%;
    background-position: bottom right;

    @media (max-width: 1440px) {
      right: -15%;
      bottom: -15%;
    }
  }

  .banner-content {
    h1 {
      font-size: 60px;
      line-height: 70px;

      span {
        font-weight: 900;
      }

      @media (max-width: 1399px) {
        font-size: 50px;
        line-height: 60px;
      }

      @media (max-width: 1199px) {
        font-size: 42px;
        line-height: 52px;
      }

      @media (max-width: 991px) {
        font-size: 32px;
        line-height: 42px;
      }
    }

    p {
      font-size: 20px;
      line-height: 30px;
      color: #e8f0fe;

      @media (max-width: 1399px) {
        font-size: 18px;
        line-height: 28px;
      }

      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .image-wrapper {
    margin-top: 7%;

    .banner-image {
      margin-bottom: -4%;
    }

    .qrcode-image {
      right: 20%;
      top: -10%;
      max-width: 21%;
      border-radius: 10px;
      box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.16);
    }
  }

  /* .poweredBySection {
    position: relative;
    right: 26%;
  } */

  .poweredby {
    bottom: 30px;
    font-size: 20px;
    position: absolute;

    @media (max-width: 1399px) {
      font-size: 18px;
    }

    @media (max-width: 991px) {
      position: relative;
      bottom: auto;
      font-size: 16px;
      left: 50%;
      width: max-content;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    @media (max-width: 360px) {
      font-size: 13px;
    }
  }
}

.banner-section .poweredby {
  /* @media (max-width: 414px) {
    left: 35%;
  }
  @media (max-width: 411px) {
    left: 29%;
  }
  @media (max-width: 375px) {
    left: 32%;
  }
  @media (max-width: 360px) {
    left: 26%;
  }
  @media (max-width: 280px) {
    left: 50%;
  } */
}

.edexaWhiteLogo {
  width: 93px;
}

.section-title {
  h2 {
    font-size: 46px;
    line-height: 55px;
    font-weight: 900;
    color: #0d0f12;

    @media (max-width: 1399px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media (max-width: 1199px) {
      font-size: 36px;
      line-height: 46px;
    }

    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 38px;
    }

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  .fixwidth-description {
    max-width: 510px;
  }
}

.sticky-title {
  position: sticky;
  top: 6rem;
}

.availableon-section {
  .hover-box-wrapper {
    @media (max-width: 1199px) and (min-width: 576px) {

      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: 0 !important;
      }
    }

    @media (max-width: 575px) {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.hover-box {
  padding: 30px;
  border-radius: 10px;
  min-height: 100%;
  position: relative;

  @extend .transiton-02s;

  @media (max-width: 1199px) {
    padding: 20px 15px 0 15px;
  }

  @media (max-width: 767px) {
    padding: 15px 0 0 0;
    margin-bottom: 10%;
  }

  .iconbox {
    height: 116px;
    width: auto;
    margin-bottom: 20px;
    padding: 10px 0;
    @extend .transiton-02s;

    @media (max-width: 1199px) {
      padding: 0;
      margin-bottom: 15px;
      height: 70px;
    }

    .icon {
      height: 100%;
      width: auto;
    }

    @media (max-width: 767px) {
      // margin-bottom: 8%;
    }
  }

  .hover-box-title {
    font-size: 17.8px;
    line-height: 28px;
    // word-spacing: 4px;
    color: #0d0f12;
    // margin-bottom: 20px;
    font-weight: 900;

    @media (max-width: 1199px) {
      font-size: 14.5px;
      line-height: 24px;
    }

    @media (max-width: 767px) {
      font-size: 17px;
      line-height: 22px;
      // margin-bottom: 15px;
      word-spacing: 2px;
    }
  }

  a {
    color: #007de4;
    font-weight: bold;
  }

  .view-more {
    margin-top: 20px;
    display: block;
    opacity: 0;
    @extend .transiton-02s;

    @media (max-width: 1199px) {
      opacity: 1;
      margin-top: 15px;
    }
  }

  .upload {
    margin-right: 14%;

    @media (max-width: 767px) {
      margin-right: 5%;
    }
  }

  .signWaterMark {
    margin-right: 14%;
  }

  .fileHash {
    margin-right: 12%;

    @media (max-width: 767px) {
      margin-right: 5%;
    }
  }

  .howwork-section__doubleArrow {
    font-size: 24px;

    @media (max-width: 767px) {
      font-size: 23px;
      transform: rotate(90deg);
      position: absolute;
      left: 46%;
      bottom: 7%;
    }

    @media (max-width: 280px) {
      bottom: -6%;
    }
  }

  @media (min-width: 1200px) {
    &:hover {
      box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);

      .iconbox {
        padding: 0;
      }

      .view-more {
        opacity: 1;
      }
    }
  }
}

.works-disable-hover {
  pointer-events: none;
}

.audit-section {
  background-color: #f8f8f8;
  overflow: hidden;

  &:before,
  &:after {
    position: absolute;
    content: "";
    height: 900px;
    width: 900px;
    background-repeat: no-repeat;
    background-size: contain;
    max-height: 84%;
    max-width: 47%;

    @media (max-width: 991px) {
      max-height: 84%;
      max-width: 60%;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      max-width: 80%;
    }
  }

  &:before {
    background-image: url("https://account-files-bucket.s3.ap-south-1.amazonaws.com/bstamp/assets/bstamp-images/about-round-right.svg");
    right: -5%;
    top: -4%;
    background-position: right top;
  }

  &:after {
    background-image: url("https://account-files-bucket.s3.ap-south-1.amazonaws.com/bstamp/assets/bstamp-images/about-round-left.svg");
    left: -2%;
    bottom: -4%;
    background-position: left bottom;

    @media (max-width: 1440px) {}
  }

  .immutable-table-image {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.16);
  }

  .qrcode-image {
    position: absolute;
    max-width: 21%;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 10px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.16);
  }

  .copycode-image {
    position: absolute;
    max-width: 70%;
    right: -5px;
    top: 22%;
    border-radius: 10px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.16);

    @media (max-width: 767px) {
      max-width: calc(70% - 3px);
    }

    @media (max-width: 400px) {
      max-width: calc(70% - 4px);
      top: 21%;
    }

    @media (max-width: 350px) {
      max-width: calc(70% - 4px);
      top: 20%;
    }
  }
}

.howwork-section {
  .hover-box-wrapper {

    // width: 25%;
    @media (max-width: 767px) {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  @media (min-width: 1400px) {
    padding-bottom: 80px;
  }

  @media (max-width: 1399px) and (min-width: 1200px) {
    padding-bottom: 60px;
  }
}

.faq-section {
  background-color: #f8f8f8;
  padding-bottom: 200px;

  @media (max-width: 1399px) {
    padding-bottom: 160px;
  }

  @media (max-width: 1199px) {
    padding-bottom: 100px;
  }

  @media (max-width: 991px) {
    padding-bottom: 80px;
  }

  @media (max-width: 767px) {
    padding-bottom: 60px;
  }
}

.faq-layout {
  .toggle-icon {
    svg {
      width: 14px;
    }
  }

  .faq-item {
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 5px;

    .card-body {
      padding: 0 30px 30px 30px;

      @media (max-width: 1399px) {
        padding: 0 20px 20px 20px;
      }

      @media (max-width: 991px) {
        padding: 0 15px 15px 15px;
      }
    }

    .collapsehead {
      cursor: pointer;
      padding: 30px 30px 30px 30px;

      @media (max-width: 1399px) {
        padding: 20px 20px 20px 20px;
      }

      @media (max-width: 991px) {
        padding: 15px 15px 15px 15px;
      }

      .tittle {
        max-width: calc(100% - 20px);
      }

      p {
        color: #0d0f12;
      }

      &.open {
        padding: 30px 30px 15px 30px;

        @media (max-width: 1399px) {
          padding: 20px 20px 15px 20px;
        }

        @media (max-width: 991px) {
          padding: 15px 15px 10px 15px;
        }
      }
    }

    +.faq-item {
      margin-top: 20px;
    }

    p {
      +p {
        margin-top: 10px;
      }
    }
  }
}

.newslatter-section-main {
  padding: 100px 112px;
  background-image: url("https://account-files-bucket.s3.ap-south-1.amazonaws.com/bstamp/assets/bstamp-images/newslatter-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  background-color: #073d83;
  color: #ffffff;
  margin: auto;
  border-radius: 20px;
  margin-top: -100px;

  p {
    color: #ffffff;
  }

  @media (max-width: 1600px) {
    max-width: calc(100% - 200px);
    padding: 80px 90px;
  }

  @media (max-width: 1399px) {
    max-width: calc(100% - 160px);
    padding: 60px 70px;
    margin-top: -80px;
  }

  @media (max-width: 1199px) {
    max-width: calc(100% - 140px);
    padding: 50px 60px;
    margin-top: -50px;
    border-radius: 15px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 30px;
    margin-top: -40px;
  }

  @media (max-width: 767px) {
    padding: 20px 15px;
    margin-top: -60px;
    border-radius: 10px;
  }

  @media (max-width: 575px) {
    max-width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
  }

  .newslatter-title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 900;
    margin-bottom: 10px;

    @media (max-width: 1399px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media (max-width: 1199px) {
      font-size: 36px;
      line-height: 46px;
    }

    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 40px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .newslatter-box {
    margin-top: 30px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;

    @media (max-width: 1199px) {
      margin-top: 20px;
    }

    @media (max-width: 767px) {
      padding: 5px;
    }

    @media (max-width: 340px) {
      padding: 15px;
    }

    form {
      @media (max-width: 340px) {
        flex-wrap: wrap;
      }
    }

    .form-group {
      width: 100%;

      .form-control {
        min-height: 100%;
        border: none;
        padding: 0 20px;
        font-size: 18px;
        line-height: 28px;

        @media (max-width: 991px) {
          font-size: 16px;
          line-height: 24px;
          padding: 0 15px;
        }

        @media (max-width: 340px) {
          padding: 6px 15px;
          border: 1px solid #dadce0;
        }
      }

      @media (max-width: 340px) {
        margin-bottom: 16px;
      }
    }
  }
}

.bstamp-footer {
  .edexa-logo {
    display: inline-block;
    padding-right: 40px;
    border-right: 1px solid #dadce0;

    @media (max-width: 1199px) {
      padding-right: 20px;

      svg {
        max-width: 130px;
      }
    }

    @media (max-width: 767px) {
      padding-right: 10px;

      svg {
        max-width: 120px;
      }
    }
  }

  .social-links {
    margin-left: 20px;
    @media (max-width: 1199px) {
      margin-left: 5px;
    }

    a {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin: 0 5px;

      @media (max-width: 1199px) {
        width: 35px;
        height: 35px;
      }

      @media (max-width: 767px) {
        width: 30px;
        height: 30px;
      }

      @extend .transiton-02s;

      img {
        width: 18px;
        height: auto;
        max-width: 18px;
        max-height: 18px;
        fill: #868686;
        filter: invert(48%) sepia(95%) saturate(754%) hue-rotate(121deg) brightness(83%) contrast(0%);
        @extend .transiton-02s;

        @media (max-width: 767px) {
          width: 15px;
          max-width: 15px;
          max-height: 15px;
        }
      }

      &:hover {
        background-color: #007de4;
        img{
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        }
        svg {
          fill: #ffffff;
        }
      }
    }
  }

  .pages {
    .pages-link {
      color: #868686;
      margin-left: 15px;
      padding-left: 15px;
      position: relative;
      @extend .transiton-02s;

      @media (max-width: 1199px) {
        margin-left: 8px;
        padding-left: 8px;
      }

      @media (max-width: 1199px) {
        font-size: 14px;
      }

      &:hover {
        color: #073d83;
      }

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 60%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 1px;
        height: 15px;
        background-color: #868686;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  .container-lg {
    @media (max-width: 1199px) and (min-width: 768px) {
      padding: 0 20px;
    }
  }
}

.modal-destop {
  background-color: #007de4;
  height: 107px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 32%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.destop-modal {
  width: 900px !important;
}

.div-or {
  display: flex;
  height: 20vh !important;
  width: 100%;
  color: #4c4f53;
  font-size: 20px !important;
}

.dragFile {
  font-size: 2rem !important;
  font-weight: 300;
  color: #4c4f53;
}

.dropZone {
  background-color: #fff !important;
  border: 2px dashed #dadce0 !important;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  padding: 65px 0;
  margin-bottom: 50;
  cursor: pointer;
}

.dropZone :focus {
  outline: none;
}

.search-menu {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  right: 0;
  z-index: 99;
  // background-image: url(../../bstamp-images/search-bg.svg);
  // background-repeat: no-repeat;
  // background-size: cover;
  // -webkit-transform: scale(0.8);
  // transform: scale(0.8);
  transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);

  .close-icon {
    position: absolute;
    cursor: pointer;
    right: 40px;
    top: 40px;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: #ffffff;

    @media (max-width: 1399px) {
      right: 30px;
      top: 30px;
      height: 45px;
      width: 45px;
    }

    @media (max-width: 767px) {
      right: 20px;
      top: 20px;
      height: 35px;
      width: 35px;
    }

    svg {
      max-width: 50%;
    }
  }

  .serch-box {
    margin-top: 200px;
    padding: 0 10%;
    transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
    -ms-transition: 0.4s ease-in-out;
    -moz-transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;

    @media (max-width: 1399px) {
      margin-top: 150px;
    }

    @media (max-width: 767px) {
      margin-top: 120px;
    }

    .search-icon {
      cursor: pointer;
      right: 40px;
      top: 40px;
      height: 56px;
      width: 56px;

      @media (max-width: 1399px) {
        right: 30px;
        top: 30px;
        height: 45px;
        width: 45px;
      }

      @media (max-width: 767px) {
        right: 20px;
        top: 20px;
        height: 35px;
        width: 35px;
      }

      svg {
        max-width: 50%;
      }
    }

    .input-group {
      border-bottom: 1px solid #868686;

      .form-control {
        font-size: 46px;
        line-height: 55px;
        font-weight: 300;
        color: #4c4f53;
        background-color: transparent;
        border: none;

        @media (max-width: 1399px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media (max-width: 1199px) {
          font-size: 36px;
          line-height: 46px;
        }

        @media (max-width: 991px) {
          font-size: 28px;
          line-height: 38px;
        }

        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 30px;
        }

        &::-webkit-input-placeholder {
          color: #4c4f53;
        }

        &::-moz-placeholder {
          color: #4c4f53;
        }

        &:-ms-input-placeholder {
          color: #4c4f53;
        }

        &:-moz-placeholder {
          color: #4c4f53;
        }
      }
    }
  }

  &.search-open {
    opacity: 1;
    margin: 0;
    background-color: #fff;
    visibility: visible;
    border-radius: 20px;
    overflow: hidden;
    overflow-y: auto;

    // -webkit-transform: scale(1);
    // transform: scale(1);
    .serch-box {
      padding: 0;
    }
  }
}

.footer-Icon {
  width: 18px;
  height: 18px;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1344px;
  }
}

@media (min-width: 576px) {
  .desktopapp-model {
    max-width: 900px;
  }

  .div-or {
    display: flex;
    // height: 20vh !important;
    width: 100%;
    color: #4c4f53;
    font-size: 20px !important;
  }
}