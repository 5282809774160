.react-pdf__Document {
  height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 100px;
  margin: auto;
}
.react-pdf__Document::-webkit-scrollbar {
  width: 0;
}

.react-pdf__Page {
  width: fit-content;
  position: static !important;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  margin: auto;
}
.modal {
  padding: 20px;
  overflow: hidden;
}
.modal-content {
  margin: auto;
}
.modal-dialog {
  max-width: 100% !important;
  height: auto !important;
}

.water-mark-modal {
  max-width: 80%;
}

.watermark-position {
  padding: 20px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%; /* Adjusted to take up the full width of the screen */
  z-index: 2;
  display: flex;
  justify-content: space-around;
}

.checkbox-custom {
  margin-right: 10px; /* Adjust spacing between checkboxes */
}

.checkbox-custom-label {
  margin-right: 20px; /* Adjust spacing between checkbox and label */
}

.comman-btn {
  margin-top: 10px; /* Adjust spacing between buttons and checkboxes */
}

/* Media query for small screens */
@media (max-width: 600px) {
  .watermark-position {
    flex-direction: column;
    align-items: center;
  }

  .checkbox-custom-label {
    margin-right: 0; /* Remove right margin for labels on small screens */
    margin-bottom: 10px; /* Add space below labels on small screens */
  }

  .comman-btn {
    margin-top: 20px; /* Increase spacing between buttons on small screens */
  }
}

.watermark-position input {
  margin-right: 10px;
}
.watermark-position label {
  color: #4c4f53;
  font-size: 16px;
}
.modal-title {
  color: #0d0f12;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
}

.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: 0 0 0 20px;
  cursor: pointer;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #fff;
  border-radius: 5px;
  border: 2px solid #dadce0;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
}
.checkbox-custom:checked + .checkbox-custom-label:after {
  content: '';
  padding: 2px;
  position: absolute;
  height: 11px;
  border: solid #073d83;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  top: 6px;
  left: 6px;
}

.comman-btn {
  padding: 10px 30px;
  font-size: 16px;
  font-family: 'LatoBold';
  color: #fff;
  background-color: #073d83;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  width: 150px;
  border: 1px solid #073d83;
  cursor: pointer;
  margin-left: auto;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.water-mark-modal {
  width: 60% !important;
}
@media screen and (max-width: 1199px) {
  .water-mark-modal {
    width: 95% !important;
  }
}
